import React, { useEffect } from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterFour from '../common/footer/FooterFour';

import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import ServiceFive from '../elements/service/ServiceFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CircleProgress from "../elements/progressbar/CircleProgress";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import Mission from "../elements/about/Mission";
import AboutOne from '../elements/about/AboutOne';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import AccordionOne from '../elements/accordion/AccordionOne';
import BrandOne from '../elements/brand/BrandOne';
import TimelineTwo from '../elements/timeline/TimelineTwo';
import TestimonialThree from '../elements/testimonial/TestimonialThree';
var BlogListData = BlogClassicData.slice(0, 3);



const BannerData = [
    {
        image: "https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/Video%20for%20Landing%20page.mp4?alt=media&token=7e2b781d-a842-4b21-9d46-93c6787965e8",
        title: "You are the architect of your future.",
        description: "From vision to execution, we bridge the gap"
    },
    // {
    //     image: "/images/bg/bg-image-14.jpg",
    //     title: "Creative Agency.",
    //     description: "We help our clients succeed by creating brand identities, digital experiences, and print materials."
    // },
    // {
    //     image: "/images/bg/bg-image-15.jpg",
    //     title: "Startup Agency.",
    //     description: "We help our clients succeed by creating brand identities, digital experiences, and print materials."
    // },
]


const DigitalAgency = () => {

    // useEffect(() => {
    //         document.querySelector('body').classList.toggle("light")
    // } , [])

    useEffect(() => {
        const handleResize = () => setScreenWidth(window?.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getScreenSize = (screenWidth) => {
        if (screenWidth < 768) {
            return 'small'; // Typically phones
        } else if (screenWidth >= 768 && screenWidth < 992) {
            return 'medium'; // Typically tablets
        } else {
            return 'large'; // Typically laptops and desktops
        }
    };
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const screenSize = getScreenSize(screenWidth);



    return (
        <>
            <SEO title="Home" />
            <main className="page-wrapper">
                
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="">
                            <video src={data.image} autoPlay loop muted style={{ width: "100%" }} className="height-950 bg-overlay bg_image" />
                            <div className="height-750" style={{marginTop: "-60%"}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="order-2 order-lg-1 col-lg-7">
                                            <div className="inner text-start">
                                                <h3 className="description" style={{ color: screenSize === 'small' ? 'black' : 'white', fontSize: 60, lineHeight: 1.2 }} dangerouslySetInnerHTML={{ __html: data.title }}></h3>
                                                <p className="title" style={{ fontSize: 18, color: screenSize === 'small' ? 'black' : 'white', }} dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                                <div className="button-group mt-10">
                                                    <a className="btn-default" href={`${process.env.PUBLIC_URL}/intake`}>Complete Intake Form</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                <Separator />
                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="We've worked on over 50 projects with 20+ clients"
                                    title="Services provided both locally & internationally"
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40">
                                <BrandOne brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}


                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle= "We've worked on over 50 projects with 20+ clients"
                                    title = "Services provide for you."
                                    description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                            serviceStyle = "gallery-style"
                            textAlign = "text-start"
                        />
                    </div>
                </div> */}
                {/* End Service Area  */}

                {/* <Separator /> */}
                <div style={{marginTop: '-5%'}} className="rwt-portfolio-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Services"
                                        title = "Experience the Difference"
                                        description = "Our services are tailored for client success"
                                    />
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-3 col-md-3 col-sm-3 col-12 mt--30 portfolio no-overlay"  />
                    </div>
                </div>
                {/* <Separator />   */}


                {/* <Separator /> */}
                <div style={{ marginTop: '-8%' }} className="rwt-progressbar-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-10 offset-lg-1">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle=""
                                    title="We see our clients as family, and we work with the challenges they face."
                                    description="Businesses of tomorrow need to be built on balanced foundations: empathy and pragmatism, foresight and insight, humanity and technology. At D.A Business Consulting, we treat organizations as partners, strongly focusing on collaboration."
                                />
                            </div>
                        </div>
                        {/* <div className="col-lg-10 offset-lg-1">
                            <CircleProgress />
                        </div> */}
                    </div>
                </div>
                
                {/* Start Mission Area  */}
                <Mission />
                {/* End of mission Area */}

                

                {/* <Separator /> */}
                <div style={{ marginTop: '-5%' }} className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Timeline"
                                    title="Working Process."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="" />
                            </div>
                        </div>
                    </div>
                </div>


                <Separator />            
                {/* Start Testimonial Area  */}
                <div className="rwt-elements-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Client Feedback"
                                    title="Testimonials"
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div>
                {/* End Testimonial Area  */}

                <Separator />          

                <CalltoActionFive />

                
                
                {/* <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Get The Latest News"
                                    title = "What Our Trending News."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <FooterFour />
            </main>
        </>
    )
}
export default DigitalAgency;