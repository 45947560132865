import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Unique Mission <br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Consulting.",
                                        "Vision.",
                                        "Creative Consulting",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>Our mission at DA Business Consulting is simple yet profound: to partner with you in unlockingthe full potential of your business. Whether it's devising innovative strategies to penetratenew markets, optimizing operational efficiency, or harnessing the power of emergingtechnologies, our dedicated team stands ready to guide you every step of the way.</p>

                            <p>In a world where change is the only constant, adaptability and foresight are the keys to success. Rest assured, with DA Business Consulting by your side, you will not only weather the storms of uncertainty but emerge stronger and more resilient than ever before.</p>

                            <p>We invite you to embark on this journey of transformation with us. Together, let us redefine the boundaries of what's possible and write your business's next chapter of success. Thank you for entrusting us with your vision. The best is yet to come.</p>
                            
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <h2 className="title">Frequently Asked Questions <br /> {" "}
                        <Typed className="theme-gradient"
                            strings={[
                                "How do I get started?",
                                "Where do I start?",
                                "What is D.A. Business Consulting?",
                            ]}
                            typeSpeed={80}
                            backSpeed={5}
                            backDelay={1000}
                            loop
                            />
                        </h2>
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
