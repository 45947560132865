import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/D.A%20Business%20Consulting%20Logo%20(Black).png?alt=media&token=4c0315f1-c2e9-4d85-89f5-94dc3dc99eec',
        title: ' Business Plan for Startups',
        description: 'Struggling to write a Business Plan? Our AI takes the hassle out, generating a strong plan to impress investors and secure funding.'
    },
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/D.A%20Business%20Consulting%20Logo%20(Black).png?alt=media&token=4c0315f1-c2e9-4d85-89f5-94dc3dc99eec',
        title: 'Data & Analytics for Startups',
        description: 'Our AI technology empowers businesses to unlock the true potential of their data. We leverage cutting-edge AI models to generate comprehensive data analysis and reports.'
    },
    // {
    //     image: '/images/service/galery-image-03.png',
    //     title: 'App Development',
    //     description: 'Variations There are many of pass.'
    // }
]
const ServiceFive = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content">
                                    <h4 className="title">
                                        <Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceFive;