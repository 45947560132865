import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import axios from 'axios';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

function IntakeForm({ props, formStyle }) {
    // const resend = new Resend
    const [result, showresult] = useState(false);
    const [business, setBusiness] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [risk, setRisk] = useState(false);
    const [mergers, setMergers] = useState(false);
    const [supply, setSupply] = useState(false);
    const [outsourcing, setOutsourcing] = useState(false);
    const apiKey = 're_CXwcksLo_G59YcDoLTKbTRMrLN3Bw7UvF';

    const sendEmail = (e) => {
        e.preventDefault();

        const intakeObj = {
            fullName: e?.target?.fullname?.value,
            companyName: e?.target?.companyname?.value,
            email: e?.target?.email?.value,
            phone: e?.target?.phone?.value,
            address: e?.target?.address?.value,
            typeOfBusiness: e?.target?.business_type?.value,
            industry: e?.target?.industry?.value,
            employees: e?.target?.employees?.value,
            yearsInBusiness: e?.target?.years_in_business?.value,
            annualRevenue: e?.target?.annual_revenue?.value,
            geographicScope: e?.target?.geographic_scope?.value,
            business: e?.target?.business?.value,
            marketing: e?.target?.marketing?.value,
            risk: e?.target?.risk?.value,
            mergers: e?.target?.mergers?.value,
            supply: e?.target?.supply?.value,
            outsourcing: e?.target?.outsourcing?.value,
            challenge: e?.target?.current_challenges?.value,
            specificNeeds: e?.target?.specific_needs?.value,
            shortTerm: e?.target?.short_term_goals?.value,
            longTerm: e?.target?.long_term_goals?.value,
            successMeasure: e?.target?.measure_success?.value,
            expectations: e?.target?.expectations?.value,
            budget: e?.target?.budget?.value,
            timeline: e?.target?.timeline?.value,
            additionalInformation: e?.target?.additional_information?.value,
            howDidYouHear: e?.target?.how_did_you_hear?.value,
            acknowledgement: e?.target?.terms_and_conditions?.value,
            date: e?.target?.date?.value
        };

        axios.get(`https://dabserver.onrender.com/dabconsulting/api/mail?key=${apiKey}`, {
            params: {
                dataObject: JSON.stringify(intakeObj),
            }
        })
            .then(response => {
                console.log('Completed:', response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>

            <h4>Personal Information</h4>

            <div className="row">
                <div className="form-group col-md-6">
                    <input type="text" name="fullname" placeholder="Full Name" className="form-control" required />
                </div>
                <div className="form-group col-md-6">
                    <input type="text" name="companyname" placeholder="Company Name (if applicable)" className="form-control" required />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <input type="email" name="email" placeholder="Contact Email" className="form-control" required />
                </div>
                <div className="form-group col-md-6">
                    <input type="text" name="phone" placeholder="Contact Phone Number" className="form-control" required />
                </div>
            </div>

            <div className="form-group">
                <input type="text" name="address" placeholder="Address" className="form-control" required />
            </div>

            <h4>Business Information</h4>

            <div className="row">
                <div className="form-group col-md-6">
                    <input type="text" name="business_type" placeholder="Type of Business" className="form-control" required />
                </div>
                <div className="form-group col-md-6">
                    <input type="text" name="industry" placeholder="Industry/Niche" className="form-control" required />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <input type="number" name="employees" placeholder="Number of Employees" className="form-control" required />
                </div>
                <div className="form-group col-md-6">
                    <input type="number" name="years_in_business" placeholder="Years in Business" className="form-control" required />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <input type="number" name="annual_revenue" placeholder="Annual Revenue" className="form-control" required />
                </div>
                <div className="form-group col-md-6">
                    <input type="text" name="geographic_scope" placeholder="Geographic Scope of Operations" className="form-control" required />
                </div>
            </div>

            <h4>Services Interested In (Check all that apply)</h4>

            <div className="form-group">
                <label htmlFor="business">Business Formation & Strategy:</label>
                <select id="business" name="business">
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="marketing">Marketing & Communication:</label>
                <select id="marketing" name="marketing">
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="risk">Risk Management:</label>
                <select id="risk" name="risk">
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="mergers">Mergers & Acquisitions:</label>
                <select id="mergers" name="mergers">
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="supply">Supply Chain Management:</label>
                <select id="supply" name="supply">
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="outsourcing">Business Process Outsourcing:</label>
                <select id="outsourcing" name="outsourcing">
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>


            <div className="form-group">
                <label>
                What are the biggest challenges your business is currently facing?
                </label>
                <textarea name="current_challenges" placeholder="" className="form-control" required></textarea>
            </div>

            <label>
                Are there any specific areas where you feel your business needs the most improvement?
            </label>
            <div className="form-group">
                <textarea name="specific_needs" placeholder="" className="form-control" required></textarea>
            </div>

            <label>
                What are your short-term (less than 1 year) business goals?
            </label>
            <div className="form-group">
                <textarea name="short_term_goals" placeholder="" className="form-control" required></textarea>
            </div>

            <label>
                What are your long-term (more than 1 year) business goals?
            </label>
            <div className="form-group">
                <textarea name="long_term_goals" placeholder="" className="form-control" required></textarea>
            </div>

            <label>
                How would you measure success in achieving these goals?
            </label>
            <div className="form-group">
                <textarea name="measure_success" placeholder="" className="form-control" required></textarea>
            </div>

            <label>
                What are your expectations from our consulting services?
            </label>
            <div className="form-group">
                <textarea name="expectations" placeholder="" className="form-control" required></textarea>
            </div>

            <label>
                Do you have a budget allocated for consulting services?
            </label>
            <div className="form-group">
                <input type="text" name="budget" placeholder="Do you have a budget allocated for consulting services?" className="form-control" required />
            </div>

            <label>
                What is your desired timeline for implementing consulting solutions?
            </label>
            <div className="form-group">
                <input type="text" name="timeline" placeholder="What is your desired timeline for implementing consulting solutions?" className="form-control" required />
            </div>

            <label>
                Is there any additional information you would like to share?
            </label>
            <div className="form-group">
                <textarea name="additional_information" placeholder="" className="form-control" required></textarea>
            </div>

            <label>
                How did you hear about us?
            </label>
            <div className="form-group">
                <select name="how_did_you_hear" required>
                    <option value="">Select an option</option>
                    <option value="Referral">Referral</option>
                    <option value="Online Search">Online Search</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Other">Other (please specify)</option>
                </select>
            </div>

            <label>
                By submitting this form, I confirm that the information provided is accurate to the best of my knowledge, and I consent to D.A Business Consulting using this information to provide me with consulting services.
            </label>
            <div className="form-group">
                <select name="terms_and_conditions" required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="Referral">No</option>
                </select>
            </div>

            <div className="form-group">
                <input type="date" name="date" className="form-control" required />
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">Submit Now</button>
            </div>

            <div className="form-group">
                {result ? <Result /> : null}
            </div>
        </form>

    )
}
export default IntakeForm;
