import React from 'react';

const BrandList = [
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/Untitled%20design%20(60).png?alt=media&token=f5e4f09b-4664-45aa-897d-9a0604889bd4'
    },
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/Decor.png?alt=media&token=3bead74b-b073-4de3-b2a9-1fc7269bb3b0'
    },
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/ZOOP%20%20Enterprise.png?alt=media&token=690de61d-b6b0-4165-a308-f1c5b586ebde'
    },
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/wan%C2%B7der%C2%B7lust.png?alt=media&token=7da62868-6314-4c1f-8dd8-4e288ab7ac1a'
    },
    {
        image: 'https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/SIGHT%20SEERS.png?alt=media&token=3a76ac7b-0070-47a7-bc28-e9b49eb19e08'
    },
]

const BrandOne = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandOne;
