import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "USA",
        description: "The services provided were excellent! The consultant I worked with provided a deep-dive research analysis to help my business find solutions to propel. He is especially passionate and knowledgeable in many business sectors based on working and interacting with him over 7 months. Whenever he was needed after hours he was present and understood that business tasks sometimes need to be completed off the clock as well.",
        name: "Franc H.",
        subtitle: "Managing Director (TWJ Electronics LLC)",
        image: "https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/D.A%20Business%20Consulting%20Logo%20(Black).png?alt=media&token=4c0315f1-c2e9-4d85-89f5-94dc3dc99eec"
    },
    {
        form: "Jamaica",
        description: "I've collaborated with D.A. Business Consulting since its inception, witnessing the company's various transitional phases aimed at enhancing our services and offerings such as digital marketing, guidance leading to business formations and risk management for our various groups of companies. Throughout this time, D.A. Business Consulting has consistently been transparent, easy to work with, and supportive at every step, evening during peak hours to help us achieve our goals and add value to our clients and services.",
        name: "Chris R.",
        subtitle: "CEO ( Alaia Events and Alaia Business Complex Company)",
        image: "https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/D.A%20Business%20Consulting%20Logo%20(Black).png?alt=media&token=4c0315f1-c2e9-4d85-89f5-94dc3dc99eec"
    },
    {
        form: "Jamaica",
        description: "It has been a pleasure working with DA Business Consulting. Their phenomenal insight into market development facilitated a different approach to our go-to-market strategy, which has brought forth a lot of new clients. Their meticulous copywriting, content creation, and keen attention to procedures and law have streamlined our business management, positioning us favorably with clients and regulatory agencies. We look forward to the future success of our business partnership.",
        name: "Omari O.",
        subtitle: "CEO (BIPJAM Limited)",
        image: "https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/D.A%20Business%20Consulting%20Logo%20(Black).png?alt=media&token=4c0315f1-c2e9-4d85-89f5-94dc3dc99eec"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p style={{fontSize: 18}} className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`${data.image}`} alt="" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















